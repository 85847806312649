<template>
  <b-modal
    id="add-user-modal"
    v-model="openModal"
    size="lg"
    title="Modifier le contact"
  >
    <div>
      <DatasetContactForm
        :current-contacts="contactsList"
        :contact="contact"
        :usergroup-disabled="true"
        :allow-delete="false"
        @change="setContact"
        @delete="isCreatingContact = false"
      />
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="outline-secondary"
          class="float-left"
          @click="hide"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          :disabled="!isFormChanged"
          variant="primary"
          class="float-right"
          @click="confirm"
        >
          {{ $t('buttons.confirm') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import DatasetContactForm from '@/components/Forms/DatasetContactForm';

export default {
  name: 'UpdateContact',

  components: {
    DatasetContactForm
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
    contactsList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    contact: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      openModal: false,
      form: {}
    }
  },

  computed: {
    isFormChanged() {
      return this.contact.contact.name !== this.form.contact.name ||
        this.contact.contact.position !== this.form.contact.position ||
        this.contact.contact.email !== this.form.contact.email ||
        this.contact.contact.phone_number !== this.form.contact.phone_number;
    }
  },

  watch: {
    open: function(newValue) {
      this.resetModal();
      this.openModal = newValue;
    },
    contact: {
      type: Boolean,
      handler(newValue) {
        this.form = newValue;
      }
    }
  },

  methods: {
    resetModal() {
      this.openModal = false;
    },
    hide() {
      this.openModal = false;
      this.$emit('hide');
    },

    setContact(e) {
      this.form = e;
    },
    confirm() {
      this.$emit('update', this.form.contact);
      this.openModal = false;
      this.$emit('hide');
    }
  }
}
</script>
