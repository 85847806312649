<template>
  <div class="page">
    <div
      v-if="routeFrom && routeFrom.name === 'SphereDetail'"
      class="back-link"
      @click="$router.push(routeFrom)"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ $t('words.organisationgroup') }}
      </span>
    </div>
    <div
      v-else
      class="back-link"
      @click="$router.push({ name: 'Organisations' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ $t('organisationDetail.organisationsList') }}
      </span>
    </div>
    <b-container
      fluid
    >
      <b-overlay
        no-wrap
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
      />
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-row>
          <b-col
            cols="8"
            class="detail-forms"
          >
            <h1>{{ currentOrganisation.display_name }}</h1>
            <OrganisationForm
              :organisation="currentOrganisation"
              @update="updateFormData"
            />
          </b-col>
          <b-col
            cols="4"
          >
            <div class="card-footer">
              <OrganisationCard
                :key="`organisation-card-${cardKey}`"
                :organisation="currentOrganisation"
                :usergroup="currentUsergroup"
                @set="setThumbnail"
              />
              <div class="side-footer">
                <b-button
                  variant="outline-secondary"
                  @click="handleSubmit(saveOrganisation)"
                >
                  {{ $t('buttons.saveChanges') }}
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>
      <b-row style="height: auto;">
        <b-col
          cols="11"
          class="users-table"
        >
          <h3 class="line-header">
            <img
              src="@/assets/icons/face_user.svg"
              alt="Icon face user"
            > {{ capitalizeFirstLetter($tc('words.user', currentUsergroupMembersCount)) }}
          </h3>
          <p style="margin: 0 0 1em 1.2em; font-size: 1em;">
            {{ $t('organisationDetail.addUsersHelp') }}
            <router-link
              :to=" { name: 'Users' }"
            >
              {{ $tc('words.user', currentUsergroupMembersCount) }}
            </router-link>
          </p>
          <SearchablePaginatedTable
            :name="'organisationMembersTable'"
            :margin="'1rem 0 1rem 1rem'"
            :loading="loadingMembersTable"
            :searchable="false"
            :route-to="'UserDetail'"
            :fields="membersFields"
            :rows="membersRows"
            :count="currentUsergroupMembersCount"
            :default-sorting="{
              sortedUp: true,
              sortedDown: false,
              sortedField: membersTableSorted.field
            }"
            :sort-function="GET_USERGROUP_MEMBERS"
            :selectable="false"
            @change-page="changeMembersTablePage"
            @sorted="setMembersTableSorted"
            @delete="removeUserFromOrganisation"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="11">
          <h3 class="line-header">
            <img
              src="@/assets/icons/face_user.svg"
              alt="Icône sphère"
            > Contacts
          </h3>
          <b-overlay
            :show="loadingContactsTable"
            :variant="'white'"
            :opacity="0.9"
            :blur="'2px'"
            rounded="sm"
          >
            <button
              v-if="!isCreatingContact"
              class="add-button"
              style="margin-bottom: 1rem;"
              @click.prevent="isCreatingContact = true"
            >
              <b-icon-plus />
              Créer un contact
            </button>
            <div
              v-if="isCreatingContact"
              class="add-contact"
            >
              <ValidationObserver v-slot="{ handleSubmit }">
                <DatasetContactForm
                  :current-contacts="contactsList"
                  :contact="newContact"
                  :usergroup-disabled="true"
                  @change="setNewContact"
                  @delete="isCreatingContact = false"
                />
                <div class="contact-form-footer">
                  <b-button
                    variant="primary"
                    @click.prevent="handleSubmit(addContact)"
                  >
                    Ajouter
                  </b-button>
                </div>
              </ValidationObserver>
            </div>
            <SearchablePaginatedTable
              :name="'organisationContactsTable'"
              :margin="'1rem 0 1rem 1rem'"
              :searchable="true"
              :search-placeholder="$t('organisationDetail.contactsTable.search')"
              :search-function="SEARCH_CONTACTS_LIST"
              :fields="contactsFields"
              :rows="contactsRows"
              :count="contactsCount"
              :default-sorting="{
                sortedUp: true,
                sortedDown: false,
                sortedField: 'name'
              }"
              :sort-function="GET_CONTACTS_LIST"
              :selectable="false"
              :allow-modification="true"
              @change-page="changeContactsTablePage"
              @sorted="setMembersTableSorted"
              @modify="modifyContact"
              @delete="removeContact"
            />
          </b-overlay>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="11"
        >
          <h3 class="line-header">
            <img
              src="@/assets/icons/sphere_planet.svg"
              alt="Icon sphere planet"
            > {{ capitalizeFirstLetter($tc('words.organisationGroup', 2)) }}
          </h3>
          <div style="margin-left: 1em;">
            <label>
              {{ $t('organisationDetail.addSphereLabel') }}
            </label>
            <p>
              {{ $t('organisationDetail.addSphereHelp') }}
            </p>
            <SearchUsergroups
              style="width: 60%;"
              :placeholder="$t('searchbars.usergroupPlaceholder')"
              :types="['group-of-organisation']"
              :limit="100"
              @select="addOrgToSphere"
            />
            <div
              v-if="selectedSpheres.length > 0"
              id="orga-spheres-container"
            >
              <div
                v-for="selectedSphere of selectedSpheres"
                :key="selectedSphere.id"
                class="orga-sphere"
              >
                {{ selectedSphere.display_name }}
                <b-icon-x
                  font-scale="1.5"
                  @click="removeOrgFromSphere"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <UpdateContact
      :open="isUpdatingContact"
      :contacts-list="contactsList"
      :contact="contactToUpdate"
      @update="updateContact"
      @hide="isUpdatingContact = false"
    />
  </div>
</template>

<script>
import OrganisationForm from '@/components/Forms/OrganisationForm.vue';
import OrganisationCard from '@/components/Cards/OrganisationCard.vue';
import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';
import DatasetContactForm from '@/components/Forms/DatasetContactForm.vue';
import UpdateContact from '@/components/Modals/UpdateContact.vue';
import SearchUsergroups from '@/components/Searchbars/SearchUsergroups.vue';

import { mapState, mapMutations, mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import datasetsAPI from '@/api/datasetsAPI.js';
import usergroupsAPI from '@/api/usergroupsAPI.js';

import { ErrorService } from '@/services/error-service.js';

export default {
  name: 'OrganisationDetail',

  components: {
    OrganisationForm,
    OrganisationCard,
    SearchablePaginatedTable,
    SearchUsergroups,
    DatasetContactForm,
    UpdateContact,
    ValidationObserver
  },

  data() {
    return {
      cardKey: 1,
      routeFrom: null,
      loading: false,
      thumbnail: null,

      // form
      formData: {},

      // tables
      loadingMembersTable: false,
      loadingContactsTable: false,
      sphere: null,
      selectedSpheres: [],

      membersTableSorted: {
        direction: '',
        field: 'user__last_name'
      },

      // contact
      isCreatingContact: false,
      newContact: {
        id: null,
        role: null,
        contact: {
          id: null,
          email: null,
          name: '',
          position: null,
          phone_number: null,
          usergroup: null
        }
      },
      isUpdatingContact: false,
      contactToUpdate: null
    };
  },

  computed: {
    ...mapState('users', ['usersList']),
    ...mapState('organisations', [
      'currentOrganisation',
      'usergroupsRoles',
      'organisationError'
    ]),
    ...mapState('usergroups', [
      'currentUsergroup',
      'usergroupsRoles',
      'currentUsergroupMembers',
      'currentUsergroupMembersCount',
      'spheres',
      'contactsCount',
      'contactsList'
    ]),
    ...mapState('table', [
      'currentTables'
    ]),

    membersFields() {
      return [
        {
          key: this.$t('organisationDetail.membersTable.lastname'),
          apiName: 'user__last_name',
          sortable: true,
          width: '',
          centered: false
        },
        {
          key: this.$t('organisationDetail.membersTable.firstname'),
          apiName: 'user__first_name',
          sortable: true,
          width: '',
          centered: false
        },
        {
          key: this.$t('organisationDetail.membersTable.username'),
          apiName: 'user__username',
          sortable: true,
          width: '',
          centered: false
        },
        {
          key: this.$t('organisationDetail.membersTable.role'),
          apiName: 'role',
          sortable: true,
          width: '',
          centered: false
        },
        {
          key: this.$t('organisationDetail.membersTable.registrationDate'),
          apiName: 'date_joined',
          sortable: true,
          width: '15%',
          centered: true
        },
        {
          key: this.$t('organisationDetail.membersTable.lastLoginDate'),
          apiName: 'user__last_login',
          sortable: true,
          width: '15%',
          centered: true
        },
        {
          key: this.$t('organisationDetail.membersTable.status'),
          apiName: 'status',
          sortable: true,
          width: '8%',
          centered: true
        },
        {
          key: this.$t('organisationDetail.membersTable.admin'),
          apiName: 'is_superuser',
          sortable: false,
          width: '6%',
          centered: true
        },
        {
          key: this.$t('organisationDetail.membersTable.remove'),
          sortable: false,
          width: '15%',
          centered: true
        }
      ];
    },
    membersRows() {
      return this.currentUsergroupMembers.map(el => {
        return {
          id: el.user.id,
          Nom:
            el.user.last_name,
          Prénom:
            el.user.first_name,
          Identifiant:
            el.user.username,
          Rôle:
            this.usergroupsRoles.find(item => item.choice === el.role) ? this.usergroupsRoles.find(item => item.choice === el.role).label : '',
          'Date d\'inscription':
            el.date_joined ? new Date(el.date_joined).toLocaleString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }) : '',
          'Dernière connexion':
             el.user.last_login ? new Date(el.user.last_login).toLocaleString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }) : '',
          Statut: [
            { choice: 0, value: 'secondary' },
            { choice: 1, value: 'warning' },
            { choice: 2, value: 'success' }
          ].find(item => item.choice === el.status).value,
          Admin: el.user.is_superuser ? el.user.is_superuser : false
        };
      });
    },
    contactsFields() {
      return [
        {
          key: this.$t('organisationDetail.contactsTable.name'),
          apiName: 'name',
          sortable: false,
          width: '30',
          centered: false
        },
        {
          key: this.$t('organisationDetail.contactsTable.email'),
          apiName: 'email',
          sortable: false,
          width: '30%',
          centered: false
        },
        {
          key: this.$t('organisationDetail.contactsTable.phone'),
          apiName: 'phone_number',
          sortable: false,
          width: '15%',
          centered: false
        },
        {
          key: this.$t('organisationDetail.contactsTable.position'),
          apiName: 'role',
          sortable: false,
          width: '15',
          centered: false
        },
        {
          key: this.$t('organisationDetail.contactsTable.modify'),
          sortable: false,
          width: '10%',
          centered: true
        },
        {
          key: this.$t('organisationDetail.contactsTable.remove'),
          sortable: false,
          width: '10%',
          centered: true
        }
      ];
    },
    contactsRows() {
      return this.contactsList.map(el => {
        return {
          id: el.id,
          Nom: el.name,
          Email: el.email,
          Téléphone: el.phone_number,
          'Position/service': el.position
        };
      });
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = from;
    });
  },

  created() {
    this.loading = true;
    this.GET_ORGANISATION_DETAIL(this.$route.params.id)
      .then(() => {
        this.cardKey += 1;
        this.newContact.contact.usergroup = this.currentOrganisation.usergroup.id;
        Promise.all([
          this.GET_USERGROUP_MEMBERS({
            id: this.currentOrganisation.usergroup.id,
            ...this.membersTableSorted
          }),
          this.GET_USERGROUP_DETAIL(this.currentOrganisation.usergroup.id),
          this.GET_ORGANISATIONS_TYPES(),
          this.GET_ORGANISATIONS_JURIDICTIONS(),
          this.GET_CONTACTS_LIST({
            usergroupId: this.currentOrganisation.usergroup.id
          })
        ])
          .then(() => {
            for (const id of this.currentUsergroup.parents) {
              usergroupsAPI.getUsergroup(id)
                .then((resp) => {
                  this.selectedSpheres.push(resp);
                });
            }
            this.loading = false;
          })
          .catch((err) => {
            console.error(err);
            this.loading = false;
          });
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });
    if (this.usergroupsRoles.length === 0) {
      this.GET_USERGROUPS_ROLES();
    }
    if (this.usersList.length === 0) {
      this.GET_USERS_LIST({
        direction: null,
        field: null
      });
    }
    // TODO: replace with search
    if (this.spheres.length === 0) {
      this.GET_USERGROUPS_SPHERES_LIST({
        direction: null,
        field: null,
        page: 1
      });
    }
    this.SET_TABLE({
      name: 'organisationMembersTable',
      table: {
        loading: false,
        currentPage: 1,
        isTableSearched: false,
        searchQuery: null
      }
    });
    this.SET_TABLE({
      name: 'organisationContactsTable',
      table: {
        loading: false,
        currentPage: 1,
        isTableSearched: false,
        searchQuery: null
      }
    });
  },

  mounted() {
    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.isUpdatingContact = false;
    });
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE'
    ]),
    ...mapActions('organisations', [
      'GET_ORGANISATION_DETAIL',
      'GET_ORGANISATIONS_LIST',
      'UPDATE_ORGANISATION',
      'REMOVE_USER_FROM_ORGANISATION',
      'SET_ORGANISATION_THUMBNAIL',
      'SET_ORGANISATION_AGREEMENT',
      'GET_ORGANISATIONS_TYPES',
      'GET_ORGANISATIONS_JURIDICTIONS'
    ]),
    ...mapActions('users', ['GET_USERS_LIST']),
    ...mapActions('usergroups', [
      'GET_USERGROUP_DETAIL',
      'GET_USERGROUPS_ROLES',
      'GET_USERGROUP_MEMBERS',
      'UPDATE_USERGROUP',
      'GET_USERGROUPS_SPHERES_LIST',
      'GET_CONTACTS_LIST',
      'SEARCH_CONTACTS_LIST'
    ]),

    updateFormData(data) {
      this.formData = data;
    },

    setThumbnail(e) {
      this.thumbnail = e;
    },

    modifyContact(e) {
      this.contactToUpdate = {
        contact: this.contactsList.find(el => el.id === e.id)
      };
      this.isUpdatingContact = true;
    },

    async updateContact(e) {
      try {
        this.loadingContactsTable = true;
        await datasetsAPI.updateContact(e.id, e);
        this.GET_CONTACTS_LIST({
          usergroupId: this.currentOrganisation.usergroup.id,
          page: this.currentTables.find(t => t.name === 'organisationContactsTable') ?
            this.currentTables.find(t => t.name === 'organisationContactsTable').currentPage :
            1
        })
          .then(() => {
            this.loadingContactsTable = false;
          })
          .catch(() => {
            this.loadingContactsTable = false;
          });
      } catch (err) {
        ErrorService.onError(err);
        this.loadingContactsTable = false;
        console.error(err);
      }
    },
    
    setNewContact(e) {
      this.newContact.contact = {
        position: e.contact.position,
        email: e.contact.email,
        name: e.contact.name,
        phone_number: e.contact.phone_number,
        usergroup_id: this.currentOrganisation.usergroup.id
      };
    },

    async addContact() {
      try {
        this.loadingContactsTable = true;
        await datasetsAPI.createContact(this.newContact.contact);
        this.isCreatingContact = false;
        await this.GET_CONTACTS_LIST({
          usergroupId: this.currentOrganisation.usergroup.id,
          page: this.currentTables.find(t => t.name === 'organisationContactsTable') ?
            this.currentTables.find(t => t.name === 'organisationContactsTable').currentPage :
            1
        });
        this.newContact = {
          id: null,
          role: null,
          contact: {
            id: null,
            email: null,
            name: '',
            position: null,
            phone_number: null,
            usergroup: null
          }
        };
        this.loadingContactsTable = false;
      } catch (err) {
        ErrorService.onError(err);
        this.loadingContactsTable = false;
      }
    },

    async removeContact(e) {
      this.loadingContactsTable = true;
      await datasetsAPI.removeContact(e.id);
      await this.GET_CONTACTS_LIST({
        usergroupId: this.currentOrganisation.usergroup.id,
        page: this.currentTables.find(t => t.name === 'organisationContactsTable') ?
          this.currentTables.find(t => t.name === 'organisationContactsTable').currentPage :
          1
      });
      this.loadingContactsTable = false;
    },

    async removeUserFromOrganisation(e) {
      try {
        const userId = this.currentUsergroupMembers.find(el => el.user.id === e.id).id;
        const usergroupId = this.currentOrganisation.usergroup.id;

        this.loading = true;

        const index = this.currentUsergroupMembers.findIndex(el => el.user.id === e.id);
        this.currentUsergroupMembers.splice(index, 1);

        await this.REMOVE_USER_FROM_ORGANISATION({ usergroupId, userId });

        Promise.all([
          this.GET_ORGANISATION_DETAIL(this.$route.params.id),
          this.GET_USERGROUP_MEMBERS({
            id: this.currentOrganisation.usergroup.id,
            ...this.membersTableSorted,
            page: this.currentTables.find(t => t.name === 'organisationMembersTable') ?
              this.currentTables.find(t => t.name === 'organisationMembersTable').currentPage :
              1
          }),
          this.GET_ORGANISATIONS_LIST({
            direction: null,
            field: null
          }),
          this.GET_USERS_LIST({
            direction: null,
            field: null
          })
        ])
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },

    saveOrganisation() {
      this.loading = true;
      this.UPDATE_ORGANISATION({
        id: this.currentOrganisation.id,
        data: this.formData.form
      })
        .then(() => {
          Promise.all([
            this.thumbnail ?
              this.SET_ORGANISATION_THUMBNAIL({
                id: this.currentOrganisation.id,
                data: this.thumbnail
              }) : Promise.resolve(),
            this.formData.agreement ?
              this.SET_ORGANISATION_AGREEMENT({
                id: this.currentOrganisation.id,
                data: this.formData.agreement
              }) : Promise.resolve()
          ]).then(() => {
            Promise.all([
              this.GET_ORGANISATION_DETAIL(this.$route.params.id),
              this.GET_ORGANISATIONS_LIST({
                direction: null,
                field: null
              }),
              this.GET_USERS_LIST({
                direction: null,
                field: null
              })
            ]).then(() => {
              this.loading = false;
            });
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    changeMembersTablePage(e) {
      this.loadingMembersTable = true;
      this.GET_USERGROUP_MEMBERS({
        id: this.currentOrganisation.usergroup.id,
        ...this.membersTableSorted,
        page: e
      })
        .then(() => {
          this.loadingMembersTable = false;
        })
        .catch((err) => {
          console.error(err);
          this.loadingMembersTable = false;
        });
    },

    changeContactsTablePage(e) {
      this.loadingContactsTable = true;
      this.GET_CONTACTS_LIST({
        usergroupId: this.currentOrganisation.usergroup.id,
        page: e
      })
        .then(() => {
          this.loadingContactsTable = false;
        })
        .catch((err) => {
          console.error(err);
          this.loadingContactsTable = false;
        });
    },

    setMembersTableSorted(e) {
      this.membersTableSorted = e;
    },

    addOrgToSphere(e) {
      const index = this.selectedSpheres.findIndex(el => el.id === e.id);
      if (index === -1) {
        this.selectedSpheres.push(e);
        this.UPDATE_USERGROUP({
          id: this.currentUsergroup.id,
          data: {
            ...this.currentUsergroup,
            parents: this.selectedSpheres.map((el) => { return el.id; })
          },
          usergroupType: 'sphere'
        });
      }
      this.sphere = null;
    },

    removeOrgFromSphere(e) {
      const index = this.selectedSpheres.findIndex(el => el.id === e.id);
      this.selectedSpheres.splice(index, 1);
      this.UPDATE_USERGROUP({
        id: this.currentUsergroup.id,
        data: {
          ...this.currentUsergroup,
          parents: this.selectedSpheres.map((el) => { return el.id; })
        },
        usergroupType: 'sphere'
      });
    }
  }
};
</script>

<style lang="less" scoped>

.container-fluid {
  margin: 1em;
  padding-bottom: 3em;
  height: fit-content;
  max-width: 100%;
  .row {
    height: fit-content;
  }
}

p {
  width: 100%;
  color: grey;
  font-style: italic;
  font-size: 0.7em;
  margin-bottom: 0.2em;
}

.add-contact {
  margin-left: 1rem;
}
.contact-form-footer {
  margin: 1rem 0;
  display: flex;
  justify-content: flex-end;
  button.btn-primary {
    margin-left: 1em;
    font-size: 1.4em;
    border: 2px solid #9BD0FF;
    border-radius: 8px;
  }
}

.b-overlay-wrap {
  table {
    width: calc(100% - 1rem);
  }
}

#orga-spheres-container {
  display: flex;
  flex-flow: row wrap;
  padding: 0.1em;
  margin: 0.5em 0;
  .orga-sphere {
    display: flex;
    margin: 0.2em 1em 0.2em 0;
    padding: 0.5em;
    border-radius: 10px;
    font-size: 0.9em;
    background-color: @blue;
    color: white;
    .b-icon {
      cursor: pointer;
    }
  }
}

</style>
