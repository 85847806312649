<template>
  <div>
    <form>
      <h3 class="line-header">
        <img
          src="@/assets/icons/company.svg"
          alt="Icone entreprise"
        > {{ capitalizeFirstLetter($tc('words.organisation')) }}
      </h3>
      <div class="form-row">
        <div class="form-group col-6">
          <label>
            {{ $t('forms.organisation.status') }}
          </label>
          <b-overlay
            :show="busy"
            rounded="lg"
            opacity="0.6"
          >
            <template #overlay>
              <div class="d-flex align-items-center">
                <b-spinner
                  small
                  type="grow"
                  variant="secondary"
                />
                <b-spinner
                  type="grow"
                  variant="dark"
                />
                <b-spinner
                  small
                  type="grow"
                  variant="secondary"
                />
                <span class="sr-only">Please wait...</span>
              </div>
            </template>
            <Multiselect
              v-model="form.status"
              :options="usergroupStatuses"
              track-by="choice"
              label="label"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('forms.organisation.status')"
              @select="changeStatus"
              @remove="removeStatus"
            />
          </b-overlay>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-8">
          <!-- ADDITIONNAL_ID -->
          <div
            v-if="isFieldVisible('additional_id', formConfig.hiddenFields)"
          >
            <ValidationProvider
              v-slot="{ classes, errors }"
              ref="additional_id"
              :rules="isFieldRequired('additional_id', formConfig.requiredFields)"
            >
              <div
                class="control"
                :class="classes"
              >
                <label
                  :class="isFieldRequired('additional_id', formConfig.requiredFields)"
                >
                  {{ $t('forms.organisation.idComp.label') }}
                </label>
                <p class="label-help">
                  {{ $t('forms.organisation.idComp.help') }}
                </p>
                <input
                  v-model="form.idComp"
                  v-sanitize
                  class="form-control"
                  type="text"
                  :placeholder="$t('forms.organisation.idComp.label')"
                >
                <span class="form-errors">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <!-- DISPLAY NAME -->
      <div
        v-if="isFieldVisible('display_name', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-10">
          <label :class="isFieldRequired('display_name', formConfig.requiredFields)">
            {{ $t('forms.organisation.name.label') }}
          </label>
          <p>
            {{ $t('forms.organisation.name.help') }}
          </p>
          <ValidationProvider
            v-slot="{ classes, errors }"
            ref="display_name"
            :rules="isFieldRequired('display_name', formConfig.requiredFields)"
          >
            <div
              class="control"
              :class="classes"
            >
              <input
                v-model="form.display_name"
                v-sanitize
                class="form-control"
                type="text"
                :placeholder="$t('forms.organisation.name.label')"
              >
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <!-- ACRONYM -->
      <div
        v-if="isFieldVisible('acronym', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-6">
          <label :class="isFieldRequired('acronym', formConfig.requiredFields)">
            {{ $t('forms.organisation.acronym') }}
          </label>
          <ValidationProvider
            v-slot="{ classes, errors }"
            ref="acronym"
            :rules="isFieldRequired('acronym', formConfig.requiredFields)"
          >
            <div
              class="control"
              :class="classes"
            >
              <input
                v-model="form.acronym"
                v-sanitize
                class="form-control"
                type="text"
                :placeholder="$t('forms.organisation.acronym')"
              >
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <!-- REGISTRATION NUMBER -->
      <div
        v-if="isFieldVisible('registration_number', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-6">
          <label :class="isFieldRequired('registration_number', formConfig.requiredFields)">
            {{ $t('forms.organisation.siret') }}
          </label>
          <ValidationProvider
            v-slot="{ classes, errors }"
            ref="registration_number"
            :rules="isFieldRequired('registration_number', formConfig.requiredFields)"
          >
            <div
              class="control"
              :class="classes"
            >
              <input
                v-model="form.registration_number"
                v-sanitize
                class="form-control"
                type="text"
                :placeholder="$t('forms.organisation.siret')"
              >
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <!-- ORGANISATION TYPE -->
      <div
        v-if="isFieldVisible('display_name', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-6">
          <label
            :class="isFieldRequired('organisation_type', formConfig.requiredFields)"
          >
            {{ $t('forms.organisation.type') }}
          </label>
          <ValidationProvider
            v-slot="{ classes, errors }"
            ref="organisation_type"
            :rules="isFieldRequired('organisation_type', formConfig.requiredFields)"
          >
            <div
              class="control"
              :class="classes"
            >
              <Multiselect
                v-model="form.organisation_type"
                :options="organisationsTypes"
                track-by="codename"
                label="display_name"
                select-label=""
                selected-label=""
                deselect-label=""
                :searchable="false"
                :placeholder="$t('forms.organisation.type')"
              />
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <!-- WEBSITE -->
      <div
        v-if="isFieldVisible('website_url', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-8">
          <label :class="isFieldRequired('website', formConfig.requiredFields)">
            {{ $t('forms.organisation.website') }}
          </label>
          <ValidationProvider
            v-slot="{ classes, errors }"
            ref="website_url"
            :rules="isFieldRequired('website', formConfig.requiredFields)"
          >
            <div
              class="control"
              :class="classes"
            >
              <input
                v-model="form.website_url"
                v-sanitize
                class="form-control"
                type="text"
                placeholder="https://"
                @focus="form.website_url === null ? form.website_url = 'https://' : null"
                @blur="form.website_url === 'https://' ? form.website_url = null : null"
              >
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <!-- DESCRIPTION -->
      <div class="form-row">
        <div class="form-group col-12">
          <label>
            {{ $t('forms.organisation.description') }}
          </label>
          <MarkdownEditor
            :value="form.description"
            @write="form.description = $event"
          />
        </div>
      </div>
      <!-- Coordonnées -->
      <h3 class="line-header">
        <img
          src="@/assets/icons/mail.svg"
          alt="Icon mail"
        > {{ $t('forms.organisation.contact') }}
      </h3>
      <!-- PHONE NUMBER -->
      <div
        v-if="isFieldVisible('phone_number', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-6">
          <label :class="isFieldRequired('phone_number', formConfig.requiredFields)">
            {{ $t('forms.organisation.phone') }}
          </label>
          <ValidationProvider
            v-slot="{ classes, errors }"
            ref="phone_number"
            :rules="{
              regex: /(?:(?:\+|00)[1-9]{2,3}|0)\s*[1-9](?:[\s.-]*\d{2}){4}/,
              required: isFieldRequired('phone_number', formConfig.requiredFields) ? true : false
            }"
          >
            <div
              class="control"
              :class="classes"
            >
              <input
                v-model="form.phone_number"
                v-sanitize
                class="form-control"
                type="text"
                :placeholder="$t('forms.organisation.phone')"
              >
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <!-- POSTAL ADDRESS -->
      <div
        v-if="isFieldVisible('postal_address', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-12">
          <label :class="isFieldRequired('postal_address', formConfig.requiredFields)">
            {{ $t('forms.organisation.address') }}
          </label>
          <ValidationProvider
            v-slot="{ classes, errors }"
            ref="postal_address"
            :rules="isFieldRequired('postal_address', formConfig.requiredFields)"
          >
            <div
              class="control"
              :class="classes"
            >
              <textarea
                v-model="form.postal_address"
                v-sanitize
                class="form-control"
                type="text"
                :placeholder="$t('forms.organisation.address')"
                rows="2"
              />
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <!-- JURISDICTION -->
      <h3
        v-if="isFieldVisible('jurisdiction', formConfig.hiddenFields)"
        class="line-header"
      >
        <img
          src="@/assets/icons/territory_map_cadastre.svg"
          alt="Icon territory map cadastre"
        > {{ $t('forms.organisation.territory.label') }}
      </h3>
      <div
        v-if="isFieldVisible('jurisdiction', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-check">
          <input
            v-model="territoireCheckbox"
            class="form-check-input-table"
            type="checkbox"
          >
          <label class="form-check-label">
            {{ $t('forms.organisation.territoryCheckboxLabel') }}
          </label>
        </div>
      </div>
      <div
        v-if="isFieldVisible('jurisdiction', formConfig.hiddenFields)"
        class="form-row"
        :class="{ disabled: territoireCheckbox }"
      >
        <div
          class="form-group col-8"
          style="margin-left: 1em;"
        >
          <label :class="isFieldRequired('phone_number', formConfig.requiredFields)">
            {{ $t('forms.organisation.territory.label') }}
          </label>
          <ValidationProvider
            v-slot="{ classes, errors }"
            ref="organisation_type"
            :rules="isFieldRequired('jurisdiction', formConfig.requiredFields)"
          >
            <div
              class="control"
              :class="classes"
            >
              <Multiselect
                v-model="form.jurisdiction"
                :class="classes"
                :options="organisationsJuridictions"
                track-by="id"
                label="display_name"
                select-label=""
                selected-label=""
                deselect-label=""
                :searchable="false"
                :preserve-search="true"
                :show-no-results="false"
                :placeholder="$t('forms.organisation.territory.placeholder')"
              />
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <!-- CONVENTION -->
      <h3
        v-if="
          isFieldVisible('agreement_signed', formConfig.hiddenFields) ||
            isFieldVisible('agreement', formConfig.hiddenFields)
        "
        class="line-header"
      >
        <img
          src="@/assets/icons/official_document_convention.svg"
          alt="Icon official document convention"
        > {{ $t('forms.organisation.convention.label') }}
      </h3>
      <div
        v-if="isFieldVisible('agreement_signed', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-check">
          <input
            v-model="form.agreement_signed"
            class="form-check-input-table"
            type="checkbox"
          >
          <label class="form-check-label">
            {{ $t('forms.organisation.conventionCheckboxLabel') }}
          </label>
        </div>
      </div>
      <div
        v-if="isFieldVisible('agreement', formConfig.hiddenFields)"
        class="form-row"
      >
        <div
          class="form-group col-8"
          style="margin-left: 2em;"
        >
          <label>
            {{ $t('forms.organisation.convention.label') }}
          </label>
          <ImportFile
            :key="`organisation-convention-${conventionKey}`"
            name="agreement"
            :type="'convention'"
            :title="organisation.agreement_file ? organisation.agreement_file.name : null"
            :file-name="organisation.agreement_file ? organisation.agreement_file.url : null"
            accept="application/*"
            @update="setAgreement"
          />
        </div>
      </div>
      <!-- PARTNER -->
      <h3 class="line-header">
        <img
          src="@/assets/icons/territory_map_cadastre.svg"
          alt="Icon territory map cadastre"
        > {{ $t('forms.organisation.partnership') }}
      </h3>
      <div class="form-row">
        <div class="form-check">
          <input
            v-model="form.partner"
            class="form-check-input-table"
            type="checkbox"
          >
          <label class="form-check-label">
            {{ $t('forms.organisation.partnershipCheckboxLabel') }}
          </label>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import ImportFile from '@/components/ImportFile.vue';
import MarkdownEditor from '@/components/Elements/MarkdownEditor.vue';
import Multiselect from 'vue-multiselect';

import { ValidationProvider, extend, configure } from 'vee-validate';
import { required, regex } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
extend('regex', {
  ...regex,
  message: 'Veuillez entrer un numéro de téléphone valide'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

const LABEL_ID_COMP = process.env.VUE_APP_LABEL_ID_COMP;

export default {
  name: 'OrganisationForm',

  components: {
    ImportFile,
    MarkdownEditor,
    Multiselect,
    ValidationProvider
  },

  props: {
    organisation: {
      type: Object,
      default: () => { return {}; }
    }
  },

  data() {
    return {
      busy: false,
      form: {
        status: {
          choice: null,
          label: null
        },
        idComp: null,
        display_name: null,
        acronym: null,
        registration_number: null,
        organisation_type: null,
        description: null,
        website_url: null,
        phone_number: null,
        postal_address: null,
        jurisdiction: null,
        agreement: null,
        agreement_signed: false,
        partner: false
      },
      territoireCheckbox: true,
      labelIdComp: LABEL_ID_COMP,
      conventionKey: 1
    };
  },

  computed: {
    ...mapState('organisations', [
      'currentOrganisation',
      'organisationsTypes',
      'organisationsError',
      'organisationsJuridictions'
    ]),
    ...mapState('usergroups', [
      'usergroupsList',
      'usergroupStatuses'
    ]),
    ...mapState('form', [
      'currentFormId',
      'currentForm'
    ]),

    formConfig() {
      return this.$config.forms.organisation;
    },
  },

  watch: {
    organisation: {
      deep: true,
      handler(newValue) {
        this.form.status.choice = newValue.usergroup.status;
        this.form.idComp = newValue.additional_id;
        this.form.status.label = this.usergroupStatuses.find(el => el.choice === this.form.status.choice).label;
        this.form.display_name = newValue.display_name;
        this.form.acronym = newValue.acronym;
        this.form.website_url = newValue.website_url;
        this.form.phone_number = newValue.phone_number;
        this.form.registration_number = newValue.registration_number;
        this.form.description = newValue.description;
        this.form.organisation_type = newValue.organisation_type;
        this.form.postal_address = newValue.postal_address;
        this.form.jurisdiction = newValue.jurisdiction;
        if (this.form.jurisdiction) {
          this.territoireCheckbox = false;
        }
        if (newValue.agreement_signed) {
          this.form.agreement_signed = true;
        }
        if (newValue.partner) {
          this.form.partner = true;
        }
        this.conventionKey += 1;
      }
    },
    form: {
      deep: true,
      handler(newValue) {
        this.SET_CURRENT_FORM({
          id: 'editOrganisation',
          form: newValue
        });
        this.$emit('update', {
          form: {
            codename: this.currentOrganisation.codename,
            additional_id: newValue.idComp,
            display_name: newValue.display_name,
            acronym: newValue.acronym,
            registration_number: newValue.registration_number,
            description: newValue.description,
            organisation_type: newValue.organisation_type,
            website_url: newValue.website_url,
            phone_number: newValue.phone_number,
            postal_address: newValue.postal_address,
            jurisdiction: this.territoireCheckbox ? null : newValue.jurisdiction,
            agreement_signed: newValue.agreement_signed,
            partner: newValue.partner,
          },
          agreement: newValue.agreement
        });
      }
    },
    organisationsError(newValue) {
      if (newValue) {
        for (const [key, value] of Object.entries(newValue)) {
          this.$refs[key].applyResult({
            errors: value,
            valid: false,
            failedRules: {}
          });
        }
      }
    }
  },

  methods: {
    ...mapMutations('form', [
      'SET_CURRENT_FORM'
    ]),
    ...mapActions('usergroups', [
      'PATCH_USERGROUP'
    ]),
    ...mapActions('organisations', [
      'GET_ORGANISATION_DETAIL',
    ]),

    setThumbnail(e) {
      const formData = new FormData();
      formData.append('file', e);
      this.form.thumbnail = formData;
    },

    setAgreement(e) {
      const formData = new FormData();
      formData.append('file', e);
      this.form.agreement = formData;
    },

    async changeStatus(e) {
      const data = { status: e.choice };
      this.busy = true;
      await this.PATCH_USERGROUP({ id: this.currentOrganisation.usergroup.id, data: data });
      await this.GET_ORGANISATION_DETAIL(this.currentOrganisation.id);
      this.busy = false;
    },

    /* prevent removing status */
    removeStatus(e) {
      this.form.status = e;
    },
  }
};
</script>
